import React from 'react';
import { NextWebVitalsMetric } from 'next/app';
import { SWRConfig } from 'swr';
import { Hydrate, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { NavigationProvider } from '~/context/use-navigation.context';
import { LayoutProvider } from '~/context/layout.context';
// import { disableClipboardData } from '~/helpers/disable-copy-on-page.helper'
import '~/styles/critical.scss';
import { useInitQueryClient } from '~/libs/queries/use-query-client';
import { AppPropsWithLayout } from '~/page-types/types';
import { SessionProvider } from 'next-auth/react';
import { NextDeliveryTimeProvider } from '~/services/basket/use-next-delivery-time';
import { GA4webVitals } from '~/libs/ga4';
import { apiClient } from '~/services/api-client';
import { captureException } from '@sentry/nextjs';

export function reportWebVitals(metric: NextWebVitalsMetric) {
    // Track performance based on the real experience
    GA4webVitals(metric);
}

export type CustomQueryProviderProps = {
    children?: React.ReactNode | React.ReactNode[];
};

export const CustomQueryProvider = ({ children }: CustomQueryProviderProps) => {
    const queryClient = useInitQueryClient();

    return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};

export default function App({ Component, pageProps: { session, ...pageProps } }: AppPropsWithLayout) {
    const getLayout = Component.getLayout && !pageProps.error ? Component.getLayout : (page: any) => page;

    return (
        <SessionProvider session={session} refetchInterval={0}>
            <NextDeliveryTimeProvider>
                <CustomQueryProvider>
                    <SWRConfig
                        value={{
                            fetcher: (url) => apiClient.auth(session).get(url),
                            onError: (error) => {
                                // Only log unknown errors
                                // TODO: what to do with errors?
                                if (!error?.name) {
                                    // eslint-disable-next-line no-console
                                    captureException(error);
                                    console.error('SWR', error);
                                }
                            },
                        }}
                    >
                        <Hydrate state={pageProps.dehydratedState}>
                            <LayoutProvider>
                                <NavigationProvider>{getLayout(<Component {...pageProps} />)}</NavigationProvider>
                            </LayoutProvider>
                        </Hydrate>
                    </SWRConfig>

                    <ReactQueryDevtools position="top-left" initialIsOpen={false} toggleButtonProps={{ className: 'fetch-debugger' }} />
                </CustomQueryProvider>
            </NextDeliveryTimeProvider>
        </SessionProvider>
    );
}
