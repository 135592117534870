import { GA4 } from '~/libs/ga4/types/ga4';
import { addItemCategories, addOptions, ConverterOpts } from '~/libs/ga4/utils';
import { Products as ProductTypes } from '~/models/products';

export function convertProduct(source: ProductTypes.IProduct, opts: ConverterOpts): GA4.TrackingProduct {
    const target: GA4.TrackingProduct = {
        item_name: source?.title?.toLowerCase() as string,
        item_id: source?.itemNo as string,
        item_brand: '',
        item_category4: 'n/a', // oe_match
        currency: opts?.currency,
        price: opts?.price,
        product_id: (opts?.productId as string) || source?.itemNo,
    };

    addOptions(target, opts);

    addItemCategories(source, target);

    return target;
}
