import { Products as ProductTypes } from '~/models/products';
import { GA4 } from '~/libs/ga4/types/ga4';
import { addItemCategories, addOptions, getDictionaryValue, ConverterOpts } from '~/libs/ga4/utils';

export function convertVariant(source: ProductTypes.IVariant, opts: ConverterOpts): GA4.TrackingProduct {
    const target: GA4.TrackingProduct = {
        item_name: source?.title?.toLowerCase() as string,
        item_id: source?.itemNo?.toLowerCase() as string,
        item_brand: source?.brand?.name?.toLowerCase() as string,
        item_category4: 'n/a', // oe_match
        currency: opts?.currency,
        price: opts?.price,
    };
    if (typeof source?.itemNo !== 'undefined' && typeof source?.productItemNo !== 'undefined') {
        const productItemNo = getDictionaryValue(source.itemNo, 'productItemNo', source.productItemNo);
        if (typeof productItemNo !== 'undefined') {
            target.product_id = productItemNo;
        }
    }

    addOptions(target, opts);

    addItemCategories(source, target);

    return target;
}
