var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"prod-20240819.4"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { CaptureConsole } from '@sentry/integrations';
import { config } from './env.config';

Sentry.init({
    dsn: config.NEXT_PUBLIC_SENTRY_DSN,
    environment: config.NEXT_PUBLIC_ENVIRONMENT,

    // Specifies whether this SDK should send events to Sentry.
    // Setting this to enabled: false doesn't prevent all overhead from Sentry instrumentation.
    // To disable Sentry completely, depending on environment, call Sentry.init conditionally.
    // We still want to experience overhead on DEV and LOCALLY, incase it affects something, so we set it here.
    enabled: config.NEXT_PUBLIC_SENTRY_ENABLED === 'true',

    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: config.NEXT_PUBLIC_ENVIRONMENT === 'prod' ? 0.2 : 1.0,

    integrations: [
        new CaptureConsole({
            levels: ['error'],
        }),
    ],
});
