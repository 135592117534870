import { MAIL_FORMAT } from '~/constants/validation';

export const validateEmail = (email: string) => {
    if (!email || email === '') return false;

    const reg = new RegExp(MAIL_FORMAT);
    return reg.test(email);
};

interface IFormData {
    [key: string]: any;
}

export const getFormData = (object: IFormData) => {
    const formData = new FormData();
    Object.entries(object).forEach(([key, val]) => formData.append(key, val));
    return formData;
};
