import { GA4 } from '~/libs/ga4/types/ga4';
import { Products as ProductTypes } from '~/models/products.d';
import { Basket as BasketTypes } from '~/models/basket';
import { Orders as OrderTypes } from '~/models/orders';
import currency from 'currency.js';
import { ItemWithCategories } from '~/libs/ga4/converters/convert-generic/convert-generic';
import { IBreadcrumb } from '~/libs/queries/routing/hooks/use-breadcrumb';
export const GA4_LOCAL_STORAGE_KEY = 'GA4_STICKY_VALUES';

export type ConverterOpts = {
    currency: string;
    price: number;
    index?: number;
    quantity?: number;
    listName?: string;
    itemCategory?: string;
    itemCategory2?: string;
    itemCategory3?: string;
    itemCategory4?: string;
    itemCategory5?: string;
    oeMatch?: boolean;
    productId?: string;
    stockStatus?: ProductTypes.StockStatusCode;
    productType?: ProductTypes.CatalogType;
    promotionId?: string;
    componentId?: string;
    componentName?: string;
};

export function addItemCategories(source: ItemWithCategories, target: GA4.TrackingProduct) {
    const [categoryLevel1 = null, categoryLevel2 = null, categoryLevel3 = null, categoryLevel4 = null, categoryLevel5 = null] = (
        source.category ? [source.category] : []
    )
        .concat(source.ancestorCategories || [])
        .filter((c) => c?.name)
        .map((c) => {
            return { path: c.id + '#' + c.name?.toLowerCase(), name: c.name?.toLowerCase() };
        });
    target.item_category = categoryLevel1?.name;
    target.item_category2 = categoryLevel2?.name;
    target.item_category3 = categoryLevel3?.name;
    // item_category4 is used for oe_match
    // item_category5 is used for stock_status
    target.item_categoryPath = [categoryLevel1, categoryLevel2, categoryLevel3, categoryLevel4, categoryLevel5]
        .filter((c) => c?.path)
        .map((c) => c?.path)
        .join(';');
}

function getStockStatusLabel(status: ProductTypes.StockStatusCode) {
    switch (status) {
        case ProductTypes.StockStatusCode.CentralDepartment:
            return 'CentralDepartment';
        case ProductTypes.StockStatusCode.ExternalStock:
            return 'ExternalStock';
        case ProductTypes.StockStatusCode.NearbyDepartment:
            return 'NearbyDepartment';
        case ProductTypes.StockStatusCode.NotInStock:
            return 'NotInStock';
        case ProductTypes.StockStatusCode.OutsideCentralDepartment:
            return 'OutsideCentralDepartment';
        case ProductTypes.StockStatusCode.PrimaryDepartment:
            return 'PrimaryDepartment';

        default:
            return undefined;
    }
}
function getProductTypeLabel(productType: ProductTypes.CatalogType) {
    switch (productType) {
        case ProductTypes.CatalogType.SparePart:
            return 'SparePart';
        default:
            return 'Universal';
    }
}

export function getDictionaryValue(itemId: string, key: string, value?: string) {
    // Retrieve the stored item list name from localStorage
    const storedValue = JSON.parse(localStorage.getItem(GA4_LOCAL_STORAGE_KEY) || '{}');
    if (typeof storedValue[itemId] !== 'undefined' && typeof storedValue[itemId][key] !== 'undefined') {
        return storedValue[itemId][key];
    } else if (typeof value !== 'undefined' && value.trim() !== '') {
        if (typeof storedValue[itemId] == 'undefined') {
            storedValue[itemId] = {};
        }
        storedValue[itemId][key] = value;
        localStorage.setItem(GA4_LOCAL_STORAGE_KEY, JSON.stringify(storedValue));
    }
    return value;
}

export function resetDictionaryValues() {
    localStorage.removeItem(GA4_LOCAL_STORAGE_KEY);
}

export function addOptions(obj: GA4.TrackingProduct, opts: ConverterOpts) {
    const listNameValue = getDictionaryValue(obj.item_id, 'listname', opts.listName?.toLowerCase());
    if (typeof listNameValue !== 'undefined') {
        obj.item_list_name = listNameValue;
    }

    if (typeof opts.quantity !== 'undefined') {
        obj.quantity = opts.quantity;
    }

    if (typeof opts.index !== 'undefined') {
        obj.index = opts.index;
    }

    if (typeof opts.productType !== 'undefined') {
        obj.item_type = getProductTypeLabel(opts.productType).toLowerCase();
    }

    if (typeof opts.oeMatch !== 'undefined') {
        obj.item_category4 = opts.oeMatch ? 'yes' : 'no';
    }

    if (typeof opts.stockStatus !== 'undefined') {
        obj.item_category5 = getStockStatusLabel(opts.stockStatus);
    }
    const promotionId = getDictionaryValue(obj.item_id, 'promotionid', opts.promotionId);
    if (typeof promotionId !== 'undefined') {
        obj.recommendation_source = promotionId;
    }
    const componentId = getDictionaryValue(obj.item_id, 'componentid', opts.componentId);
    if (typeof componentId !== 'undefined') {
        obj.component_id = componentId;
    }
    const componentName = getDictionaryValue(obj.item_id, 'componentname', opts.componentName);
    if (typeof componentName !== 'undefined') {
        obj.component_name = componentName;
    }

    if (typeof obj.product_id === 'undefined') {
        const productId = getDictionaryValue(obj.item_id, 'productItemNo', opts.productId);
        if (typeof productId !== 'undefined') {
            obj.product_id = productId;
        }
    }
}

export function isVariant(obj: unknown): obj is ProductTypes.IVariant {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    return typeof obj === 'object' && 'shortDescription' in obj;
}

export function isProductTile(obj: unknown): obj is ProductTypes.IProductTile {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    return typeof obj === 'object' && 'productTileType' in obj;
}

export function isBasketItem(obj: unknown): obj is BasketTypes.IBasketItemDto {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    return typeof obj === 'object' && 'totalPrice' in obj;
}

export function isFavoriteProduct(obj: unknown): obj is ProductTypes.IFavoriteProduct {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    return typeof obj === 'object' && 'categoryParent' in obj;
}

export function isVariantLight(obj: unknown): obj is ProductTypes.IVariantLight {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    return typeof obj === 'object' && 'subGroup' in obj;
}

export function isInvoiceItem(obj: unknown): obj is OrderTypes.IInvoiceItem {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    return typeof obj === 'object' && 'possibleToCreditQuantity' in obj;
}

export function isProduct(obj: unknown): obj is ProductTypes.IProduct {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    return typeof obj === 'object' && 'variants' in obj;
}

export function multiplyCurrency(price: number, quantity: number): number {
    return currency(price).multiply(quantity).value;
}

export const lowerCamel = (str: string) => {
    try {
        return str?.toLocaleLowerCase().replaceAll(' ', '_');
    } catch {
        return '';
    }
};

// Finds a group in the basket with a specific car id
export function findBasketGroupByCar(carId: string) {
    return function (group: BasketTypes.IBasketGroupDto) {
        return group.basketCarInfo?.carId === carId;
    };
}

// Finds a group in the basket with no car attached.
export function findBasketGroupWithoutCar(group: BasketTypes.IBasketGroupDto) {
    return !group.basketCarInfo;
}

export function getBasketGroup(groups: BasketTypes.IBasketGroupDto[], carId?: string) {
    if (carId) {
        return groups.find(findBasketGroupByCar(carId));
    }

    return groups.find(findBasketGroupWithoutCar);
}

export function getFlattendedGroupItems(groups: BasketTypes.IBasketGroupDto[]) {
    return groups
        .map((grp) => grp.items)
        .reduce(function (a, b) {
            return (a || []).concat(b || []);
        }, []);
}

export function findBasketItemByItemId(itemId: string) {
    return function (item: BasketTypes.IBasketItemDto) {
        return item.itemId === itemId;
    };
}

export function getListNameFromBreadcrumb(breadcrumb?: IBreadcrumb) {
    return breadcrumb?.items[breadcrumb?.items.length - 1]?.title ?? '';
}
