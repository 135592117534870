import { Products as ProductTypes } from '~/models/products';
import { Basket as BasketTypes } from '~/models/basket';
import {
    ConverterOpts,
    isBasketItem,
    isFavoriteProduct,
    isInvoiceItem,
    isProduct,
    isProductTile,
    isVariant,
    isVariantLight,
    resetDictionaryValues,
} from '~/libs/ga4/utils';
import { convertVariant } from '~/libs/ga4/converters/convert-variant';
import { convertProductTile } from '~/libs/ga4/converters/convert-product-tile';
import { convertBasketItem } from '~/libs/ga4/converters/convert-basket-item';
import { convertFavoriteProduct } from '~/libs/ga4/converters/convert-favorite-product';
import { convertVariantLight } from '~/libs/ga4/converters/convert-variant-light';
import { Orders as OrdersTypes } from '~/models/orders';
import { convertInvoiceItem } from '~/libs/ga4/converters/convert-invoice-item';
import { convertProduct } from '~/libs/ga4/converters/convert-product';
import { getCatalogType } from '~/helpers/get-catalog-type';

export type ConverterItem =
    | ProductTypes.IVariant
    | ProductTypes.IProduct
    | ProductTypes.IProductTile
    | BasketTypes.IBasketItemDto
    | ProductTypes.IFavoriteProduct
    | ProductTypes.IVariantLight
    | OrdersTypes.IVehicleInvoiceDetailItem;

export type ConverterItemWithOpts = ConverterItem & {
    opts: ConverterOpts;
};

export type ItemWithCategories = ProductTypes.IVariant | ProductTypes.IProduct | BasketTypes.IBasketItemDto | OrdersTypes.IVehicleInvoiceDetailItem;

export function convertGeneric(generic: ConverterItem, opts: ConverterOpts) {
    if (typeof opts.productType == 'undefined') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        if (typeof generic === 'object' && 'url' in generic) {
            opts.productType = getCatalogType(generic['url']);
        }
    }
    if (isVariant(generic)) {
        return convertVariant(generic, opts);
    }

    if (isProductTile(generic)) {
        return convertProductTile(generic, opts);
    }

    if (isBasketItem(generic)) {
        return convertBasketItem(generic, opts);
    }

    if (isFavoriteProduct(generic)) {
        return convertFavoriteProduct(generic, opts);
    }

    if (isVariantLight(generic)) {
        return convertVariantLight(generic, opts);
    }

    if (isInvoiceItem(generic)) {
        return convertInvoiceItem(generic, opts);
    }

    if (isProduct(generic)) {
        return convertProduct(generic, opts);
    }

    throw new Error(`convertGeneric: could not determine converter for object with keys: ${Object.keys(generic).join(', ')}`);
}

export function resetBasket() {
    resetDictionaryValues();
}
